import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from './router'

loadFonts()

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuosi1EulpUtJaPeQLTHOPofmm12Qb4xE",
  authDomain: "rohrreinigung-seifer.firebaseapp.com",
  projectId: "rohrreinigung-seifer",
  storageBucket: "rohrreinigung-seifer.appspot.com",
  messagingSenderId: "848096401602",
  appId: "1:848096401602:web:96daee00597aabd998835b",
  measurementId: "G-4KVR6MYCJS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

// provide firebase functions in components
export { app, analytics, functions, httpsCallable }

createApp(App)
  .use(router)
  .use(vuetify)
  .use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY})
  .mount('#app')
