<template>
    <v-container class="contact-container" fluid>
        <div style="position: absolute; top: 0;" v-intersect="() => $emit('intersect', 'contact')"></div>
        <v-row class="block-row card-row">
            <a :href="'tel:' + tel">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="contact-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="contact-card-text">
                            <v-icon icon="mdi-phone" size="75"></v-icon>
                            <p>Telefon</p>
                            <p>{{ tel }}</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </a>
            <a :href="'mailto:' + mail">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="contact-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="contact-card-text">
                            <v-icon icon="mdi-email" size="75"></v-icon>
                            <p>E-Mail</p>
                            <p>{{ mail }}</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </a>
        </v-row>
        <v-row class="contact-form-container">
            <v-card :elevation="isIntersecting ? 12 : 3" class="contact-form-card" v-intersect="{
                handler: onIntersect,
                options: {
                    threshold: [0, 0.5, 1.0]
                }
            }">
                <h2>Kontaktieren Sie uns!</h2>
                <v-divider></v-divider>
                <form class="contact-form" @submit.prevent="submit" method="POST" name="contact" data-netlify="true">
                    <v-text-field class="contact-form-field" v-model="name.value.value" :counter="10"
                        :error-messages="name.errorMessage.value" label="Name*" name="name" id="name"></v-text-field>

                    <v-text-field class="contact-form-field" v-model="phone.value.value" :counter="7"
                        :error-messages="phone.errorMessage.value" label="Telefonnummer*" name="phone"
                        id="phone"></v-text-field>

                    <v-text-field class="contact-form-field" v-model="email.value.value"
                        :error-messages="email.errorMessage.value" label="E-Mail*" name="email" id="email"></v-text-field>

                    <v-select class="contact-form-field" v-model="select.value.value" :items="items"
                        :error-messages="select.errorMessage.value" label="Leistung" name="service" id="service"></v-select>

                    <v-text-field class="contact-form-field" v-model="message.value.value" :counter="0" label="Nachricht"
                        name="message" id="message"></v-text-field>

                    <v-checkbox v-model="checkbox.value.value" :error-messages="checkbox.errorMessage.value" value="1"
                        label="Ich akzeptiere die Datenschutbestimmungen" type="checkbox"
                        style="margin-bottom: 15px;"></v-checkbox>


                    <div class="form-btns">
                        <v-btn type="submit">
                            <p>Bestätigen</p>
                        </v-btn>

                        <v-btn @click="handleReset">
                            <p>Reset</p>
                        </v-btn>
                    </div>
                </form>
            </v-card>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios';
import { functions, httpsCallable } from '../main';

const verifyCaptcha3 = httpsCallable(functions, 'verifyCaptcha3');

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const { validate, handleReset, resetForm, errors } = useForm({
    validationSchema: {
        name(value) {
            if (value?.length >= 2) return true

            return 'Name muss aus mindestens zwei Zeichen bestehen.'
        },
        phone(value) {
            if (value?.length > 9 && /[0-9-]+/.test(value)) return true

            return 'Telefonnummer muss aus 9 Zahlen bestehen.'
        },
        email(value) {
            if (/^[a-z0-9.-]+@[a-z.-]+\.[a-z]+$/i.test(value))
                return true

            return 'Keine gültige E-Mail.'
        },
        message() {
            return true
        },
        select() {
            return true
        },
        checkbox(value) {
            if (value === '1') return true

            return 'Bitte auswählen.'
        },
    },
})

const setErrors = (validationErrors) => {
    // Set the validation errors to the 'errors' data property
    for (const key in validationErrors) {
        errors[key] = validationErrors[key];
    }
};

const submit = async (evt) => {
    try {
        // Check if reCAPTCHA is available and get the token
        let token = null;
        let validationResult = null;
        try {
            await recaptchaLoaded();
            token = await executeRecaptcha('login');
        } catch (error) {
            console.error('Error while loading or executing reCAPTCHA:', error);
        }

        // If reCAPTCHA is available and the token is obtained, verify the captcha
        if (token) {
            try {
                const recaptchaResponse = await verifyCaptcha3(token);

                // Check if the reCAPTCHA was successful (success is either true or not)
                if (recaptchaResponse.data.success) {
                    // If the reCAPTCHA is successful, proceed with form validation
                     validationResult= await validate();
                    if (validationResult.valid) {
                        // If the form is valid, submit the form data
                        handleSubmit(evt.target);
                    } else {
                        // If the form is invalid, set the validation errors
                        setErrors(validationResult.errors);
                    }
                } else {
                    // If reCAPTCHA fails with success false, display an alert to the user
                    alert('You might be a bot. Please try again.');
                }
            } catch (error) {
                console.error('Error while verifying reCAPTCHA:', error);
                // Proceed with the usual behavior (form submission) even if reCAPTCHA verification fails
                validationResult = await validate();
                if (validationResult.valid) {
                    // If the form is valid, submit the form data
                    handleSubmit(evt.target);
                } else {
                    // If the form is invalid, set the validation errors
                    setErrors(validationResult.errors);
                }
            }
        } else {
            // If reCAPTCHA is unavailable or not executed, proceed with the usual behavior (form submission)
            handleSubmit(evt.target);
        }
    } catch (error) {
        console.error('Error while processing form submission:', error);
    }
};

const handleSubmit = async (values) => {
    try {
        const response = await axios.post("https://formsubmit.co/ajax/b5d5580d8ddc7868e4419f15351067be", {
            name: values.name.value,
            message: values.message.value != null ? values.message.value : "no message provided",
            email: values.email.value,
            service: values.service.value != null ? values.service.value : "no option selected",
            phone: values.phone.value
        });

        // Handle the response
        console.log(response);
        resetForm();

        alert("Vielen Dank für ihre Anfrage " + values.name.value + ".");
    } catch (error) {
        // Handle the error
        console.error(error);
        alert("Leider hat etwas bei ihrer Anfrage nicht funktioniert.");
    }
}

const name = useField('name')
const phone = useField('phone')
const email = useField('email')
const message = useField('message')
const select = useField('select')
const checkbox = useField('checkbox')

const items = ref([
    'Abflussreinigung',
    'Rohrreinigung',
    'Kanalreinigung',
    'Keller und Schachtreinigung',
    'Kanal/Rohr-Sanierung',
    'Kanal/Rohr TV-Untersuchung',
    'Sonstiges'
])
</script>

<script>
export default {
    data: () => ({
        isIntersecting: false
    }),
    methods: {
        onIntersect(isIntersecting, entries, observer) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            isIntersecting
            observer

            setTimeout(() => { this.isIntersecting = entries[0].intersectionRatio >= 0.5 }, 500)
        }
    },
    computed: {
        mail() {
            return process.env.VUE_APP_MAIL
        },
        tel() {
            return process.env.VUE_APP_TEL
        }
    }
}
</script>

<style>
.contact-container {
    width: 80%;
    max-width: 3000px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-bottom: 100px;
    margin-top: 100px;
    position: relative;
    padding: 0;
}

.card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex: 1;
    height: 100%;
    min-width: 300px;
}

.card-row a {
    width: 100%;
    height: 48%;
    margin-left: -20px;
}

.contact-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-card-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.contact-card-text p:nth-of-type(1) {
    font-weight: bold;
}

.contact-card-text p {
    font-size: 18px;
    line-height: 120%;
    margin-top: 10px;
}

.contact-form-container h2 {
    padding: 25px;
    text-align: center;
    background: #2496C9;
    color: white;
    font-size: 2.5rem;
}

.contact-form-container {
    flex: 2.5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-top: 0 !important;
    margin-right: 50px;
}

.contact-form-card {
    width: 100%;
}

.contact-form {
    padding: 50px;
    background-color: #2496C9;
}

.contact-form-field {
    background-color: white;
    margin-bottom: 10px;
}

.v-input__details {
    background-color: #2496C9;
}

.v-input,
.v-label,
.wn-menu-content-list .v-select-select option,
.contact-form p {
    font-size: 14px;
}

.form-btns {
    display: flex;
    width: 100%;
}

.form-btns .v-btn {
    width: 150px;
    margin: 10px;
    margin-left: 0;
}

@media only screen and (max-width: 1000px) {

    .contact-container {
        flex-direction: column;
        width: 90%;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .contact-form-container {
        width: 100%;
        margin-right: 0;
    }

    .card-row {
        margin-bottom: 0;
        margin-top: 20px;
        width: 100%;
        flex-direction: row;
    }

    .card-row a {
        width: 48%;
        margin: 0;
        margin-bottom: 50px;
        height: 300px;
    }

    .form-btns {
        justify-content: space-between;
    }

    .form-btns .v-btn {
        width: 45%;
    }

    .contact-form {
        padding: 25px;
    }

}

@media only screen and (max-width: 800px) {
    .card-row a {
        margin-bottom: 25px;
        width: 100%;
    }

    .contact-container {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}
</style>