<template>
    <v-container class="faq-container" v-intersect="() => $emit('intersect', 'faq')" fluid>
        <h2>Haben Sie noch Fragen?</h2>
        <v-expansion-panels variant="accordion" class="faq-panel">
            <v-expansion-panel v-for="faq in faqs" :key="faq.id" class="faq-panel-item">
                <v-expansion-panel-title class="faq-panel-title">
                    {{ faq.title }}
                </v-expansion-panel-title>
                <v-expansion-panel-text class="faq-panel-text">
                    {{ faq.text }}
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            faqs: [
                {
                    id: 1,
                    title: "Wie erkenne ich eine verstopfte Rohrleitung?",
                    text: "Eine verstopfte Rohrleitung lässt sich anhand verschiedener typischer Anzeichen erkennen. Dazu gehören langsame oder gestaute Abflüsse, gurgelnde Geräusche aus den Rohren, übelriechende Abflüsse, Rückstau von Wasser oder Abwasser in Toiletten oder Waschbecken sowie Feuchtigkeits- oder Wasserflecken an Wänden oder Böden in der Nähe von Rohrleitungen. Wenn Sie eines oder mehrere dieser Symptome bemerken, ist es ratsam, umgehend eine professionelle Rohrreinigungsfirma zu kontaktieren, um das Problem frühzeitig zu beheben. Unsere Rohrreinigungsexperten stehen Ihnen zur Verfügung, um eine schnelle Diagnose und effektive Lösung für Ihre verstopfte Rohrleitung bereitzustellen."
                },
                {
                    id: 2,
                    title: "Kann ich eine Rohrverstopfung selbst beheben?",
                    text: "Es ist durchaus möglich, kleinere Rohrverstopfungen eigenständig zu beheben. Hierfür können handelsübliche Abflussreiniger oder mechanische Werkzeuge wie eine Saugglocke oder ein Rohrreinigungsschlangen-Set zum Einsatz kommen. Diese können in vielen Fällen ausreichen, um die Verstopfung zu lösen. Es ist jedoch wichtig zu beachten, dass bei hartnäckigen oder wiederkehrenden Verstopfungen eine professionelle Rohrreinigungsfirma kontaktiert werden sollte. Unsere Experten verfügen über das Fachwissen, die richtigen Werkzeuge und Techniken, um selbst die anspruchsvollsten Verstopfungen effizient zu beseitigen. Durch die professionelle Rohrreinigung können Schäden oder weitere Probleme vermieden werden. Bei Bedarf stehen wir Ihnen gerne zur Verfügung, um Ihnen bei der Lösung Ihrer Rohrverstopfung zu helfen und einen reibungslosen Wasserfluss wiederherzustellen."
                },
                {
                    id: 3,
                    title: "Wie lange dauert es, eine Rohrverstopfung zu beseitigen? ",
                    text: "Die Dauer der Rohrreinigung ist von der Art und Schwere der Verstopfung abhängig. Unsere professionelle Rohrreinigungsfirma kann in den meisten Fällen Verstopfungen innerhalb einer Stunde oder weniger effizient beseitigen. Bei anspruchsvolleren oder schwerwiegenderen Problemen kann die Bearbeitungszeit jedoch etwas länger sein. Wir sind bestrebt, Ihre Rohrprobleme so schnell wie möglich zu lösen, um Ihnen einen reibungslosen Ablauf zu gewährleisten."
                },
                {
                    id: 4,
                    title: "Welche Methoden werden zur Rohrreinigung verwendet?",
                    text: "Eine professionelle Rohrreinigungsfirma setzt verschiedene effektive Methoden zur Beseitigung von Verstopfungen ein. Dazu gehören mechanische Methoden wie der Einsatz von Rohrreinigungsschlangen und Hochdruckspülungen sowie chemische Lösungen und Enzyme. Die Wahl der Methode richtet sich nach der Art der Verstopfung und den individuellen Gegebenheiten vor Ort. Mechanische Methoden sind besonders wirksam bei hartnäckigen Verstopfungen, während chemische Lösungen und Enzyme oft bei organischen Ablagerungen oder Fettansammlungen verwendet werden. Als erfahrene Rohrreinigungsfirma nutzen wir eine Kombination dieser Methoden, um eine effiziente und nachhaltige Rohrreinigung zu gewährleisten. Unsere Fachkräfte stehen bereit, um Ihre Rohrprobleme professionell zu lösen und Ihre Rohrleitungen wieder reibungslos fließen zu lassen."
                },
                {
                    id: 5,
                    title: "Kann eine Rohrreinigungsfirma auch vorbeugende Maßnahmen treffen?",
                    text: "Ja, eine professionelle Rohrreinigungsfirma kann gezielte vorbeugende Maßnahmen ergreifen, um zukünftige Rohrverstopfungen zu verhindern. Dazu gehört unter anderem die regelmäßige Inspektion der Rohrleitungen, um potenzielle Probleme frühzeitig zu erkennen. Des Weiteren bieten wir Reinigungs- und Wartungsdienste für Abflusssysteme an, um Ablagerungen zu entfernen und eine optimale Funktionalität sicherzustellen. Darüber hinaus geben wir Ihnen wertvolle Empfehlungen zur richtigen Nutzung und Pflege Ihrer Rohre, um Verstopfungen vorzubeugen. Unsere Experten stehen Ihnen zur Seite, um individuelle Lösungen für Ihre spezifischen Anforderungen zu entwickeln und eine langfristige optimale Rohrleistung zu gewährleisten. Kontaktieren Sie uns gerne für weitere Informationen zu unseren vorbeugenden Maßnahmen und Dienstleistungen."
                },
                {
                    id: 6,
                    title: "Sind die Dienstleistungen einer Rohrreinigungsfirma kostenpflichtig?",
                    text: "Ja, die Dienstleistungen einer professionellen Rohrreinigungsfirma sind in der Regel kostenpflichtig. Die genauen Kosten können je nach Art der Verstopfung, dem Arbeitsaufwand, den benötigten Materialien und der individuellen Preisgestaltung der Firma variieren. Es empfiehlt sich, im Voraus eine Preisabsprache zu treffen oder einen Kostenvoranschlag einzuholen, um volle Kostentransparenz zu gewährleisten. Bei unserer Rohrreinigungsfirma legen wir Wert auf faire und transparente Preisgestaltung, um unseren Kunden qualitativ hochwertige Dienstleistungen zu angemessenen Preisen anzubieten."
                },
                {
                    id: 7,
                    title: "Was sind mögliche Ursachen für Rohrverstopfungen?",
                    text: "Rohrverstopfungen können durch verschiedene Faktoren verursacht werden. Häufige Ursachen sind Ablagerungen von Fett, Öl oder Seifenrückständen, Ansammlung von Haaren, Verstopfung durch Fremdkörper oder unangemessene Materialien, Wurzeleinwuchs, Korrosion oder Verschleiß des Rohrsystems. Diese Faktoren können im Laufe der Zeit zu Verengungen oder vollständigen Blockaden führen, die den Wasserfluss behindern. Um solche Verstopfungen zu beseitigen und weitere Schäden zu verhindern, empfehlen wir eine professionelle Rohrreinigung durch unsere Experten. Mit unserer Erfahrung und spezialisierten Werkzeugen können wir die Ursache der Verstopfung ermitteln und eine effektive Lösung bieten. Kontaktieren Sie uns gerne, um mehr über unsere Rohrreinigungsdienstleistungen und unsere Expertise bei der Behebung von Rohrverstopfungen zu erfahren."
                },
                {
                    id: 8,
                    title: "Wie schnell sind Sie bei mir?",
                    text: "Wenn Sie sofortige Hilfe benötigen, machen wir uns umgehend auf den Weg zu Ihnen. Bei weniger dringenden Aufträgen können wir auch einen Termin vereinbaren. Wir legen großen Wert darauf, Ihnen eine zeitnahe Lösung anzubieten, um Ihren Anforderungen gerecht zu werden."
                }
            ]
        };
    }
};
</script>

<style>
.faq-container {
    width: 80%;
    padding: 0;
    margin-bottom: 100px;
    z-index: 0;
}

.faq-container h2 {
    text-align: center;
    font-size: 2rem;
    background: #333;
    color: white;
    padding: 10px;
}

.faq-panel-item {
    background-color: whitesmoke;
    color: black;
    width: 100%;
}

.faq-panel-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
}

.faq-panel-text {
    font-size: 14px;
}

@media only screen and (max-width: 1000px) {

.faq-container {
    width: 90%;
    margin-bottom: 50px;
}

}
</style>