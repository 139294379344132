import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ImprintView from '../views/ImprintView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import BackLinkView from '../views/BackLinkView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImprintView
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: PrivacyView
  },
  {
    path: '/standorte',
    name: 'standorte',
    component: BackLinkView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    to
    from
    savedPosition
    // always scroll to top
    return { top: 0 }
  },
})

export default router
