<template>
    <v-container class="privacy-container" fluid>
        <v-row class="sub-nav">
            <v-col class="sub-col">
                <v-img src="../assets/logo.webp" id="logo" contain></v-img>
            </v-col>
            <v-col class="sub-col">
                <router-link :to="'/'">
                    <v-btn color="#fff" variant="tonal" class="home-btn">
                        Startseite
                    </v-btn>
                </router-link>
            </v-col>
            <v-col class="sub-col">
                <v-btn class="sub-phone" prepend-icon="mdi-phone" variant="tonal">
                    <a :href="'tel:' + tel">{{ tel }}</a>
                </v-btn>
            </v-col>
        </v-row>

        <div class="privacy">
            <v-card class="privacy-card" :elevation="3">
                <h3>Datenschutzerklärung</h3>
                <p>
                    <strong>§ 1 Information über die Erhebung personenbezogener Daten</strong>
                    <br><br>
                    (1) Hiermit informieren wir Sie über die Erhebung personenbezogener Daten bei Nutzung unserer Website.
                    Personenbezogene Daten sindalle Daten, die Rückschlüsse auf Ihre Person zulassen, z. B. Name, Adresse,
                    E-Mail-Adresse, Nutzerverhalten.
                    <br><br>
                    (2) Verantwortlicher im Sinne des Art. 4 Abs. 7 der EU-Datenschutz-Grundverordnung (im Folgenden: DSGVO)
                    ist: Flying Eagle UG (haftungsbeschränkt), Höhenweg 7, 35452 Heuchelheim, Geschäftsführer: Patrick Seck
                    (siehe auch unser Impressum). Unseren Datenschutzbeauftragten erreichen Sie unter
                    [info@flying-eagle24.de] oder unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.
                    <br><br>
                    (3) Wenn Sie mit uns per E-Mail oder über ein Online-Kontaktformular Kontakt aufnehmen, werden die von
                    Ihnen mitgeteilten Daten(z.B. Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns
                    gespeichert, um Ihr Anliegen zu bearbeiten bzw. Ihre Fragen zu beantworten. Die hierbei anfallenden
                    Daten löschen wir, sobald die weitere Speicherung nicht mehr erforderlich ist,oder schränken die
                    Verarbeitung ein, sofern gesetzliche Aufbewahrungspflichten bestehen.
                    <br><br>
                    (4) Sofern wir für einzelne Funktionen unseres Angebots mit externen Dienstleistern zusammenarbeiten
                    oder Ihre Daten für werblicheZwecke nutzen möchten, werden wir Sie untenstehend im Detail über die
                    jeweiligen Vorgänge informieren. Dabei nennen wir auchdie festgelegten Kriterien der Speicherdauer.
                    <br><br>
                    <strong>§ 2 Ihre Rechte</strong>
                    <br><br>
                    (1) Sie haben uns gegenüber die folgenden Rechte bezüglich Ihrer personenbezogenen Daten:
                    <br><br>
                <ul>
                    <li>Recht auf Auskunft,</li>
                    <li>Recht auf Berichtigung oder Löschung,</li>
                    <li>Recht auf Einschränkung der Verarbeitung,</li>
                    <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                    <li>Recht auf Datenübertragbarkeit.</li>
                </ul>
                <br>
                (2) Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer
                personenbezogenen Daten durch uns zu beschweren
                <br><br>
                <strong>§ 3 Erhebung personenbezogener Daten bei Besuch unserer Website</strong>
                <br><br>
                (1) Bei der bloß informatorischen Nutzung der Website, also wenn Sie sich nicht registrieren oder uns
                anderweitig Informationen übermitteln, erheben wir automatisch nur diejenigen personenbezogenen Daten, die
                Ihr Browser an unseren Server übermittelt. Es handelt sich hierbei um die folgenden Daten, die für uns
                technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu
                gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DSGVO):
                <br><br>
                <ul>
                    <li>IP-Adresse</li>
                    <li>Datum und Uhrzeit der Anfrage</li>
                    <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                    <li>Inhalt der Anforderung (konkrete Seite)</li>
                    <li>Zugriffsstatus/HTTP-Statuscode</li>
                    <li>jeweils übertragene Datenmenge</li>
                    <li>Website, von der die Anforderung kommt</li>
                    <li>Browser</li>
                    <li>Betriebssystem und dessen Oberfläche</li>
                    <li>Sprache und Version der Browsersoftware.</li>
                </ul>
                <br>
                (2) Zusätzlich zu den zuvor genannten Daten werden bei Ihrer Nutzung unserer Website Cookies auf Ihrem
                Rechner gespeichert. Hierbei handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen
                verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie setzt (hier
                durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren
                Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu
                machen.
                <br><br>
                (3) Einsatz von Cookies:
                <br><br>
                (a) Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert
                werden:
                <br><br>
                <ul>
                    <li>Transiente Cookies (dazu b)</li>
                    <li>Persistente Cookies (dazu c).</li>
                </ul>
                <br>
                (b) Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen
                insbesondere die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene
                Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt
                werden, wenn Sie zu unserer Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich
                ausloggen oder den Browser schließen.
                <br><br>
                (c) Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
                unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
                <br><br>
                (d) Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von
                Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht alle
                Funktionen dieser Website nutzen können.
                <br><br>
                <strong>§ 4 Weitere Funktionen und Angebote unserer Website</strong>
                <br><br>
                (1) Neben der rein informatorischen Nutzung unserer Website bieten wir verschiedene Leistungen an, die Sie
                bei Interesse nutzen können. Dazu müssen Sie in der Regel weitere personenbezogene Daten angeben, die wir
                zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur
                Datenverarbeitung gelten.
                <br><br>
                (2) Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden von uns
                sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden regelmäßig kontrolliert.
                <br><br>
                (3) Weiterhin können wir Ihre personenbezogenen Daten an Dritte weitergeben, wenn Aktionsteilnahmen,
                Gewinnspiele, Vertragsabschlüsse oder ähnliche Leistungen von uns gemeinsam mit Partnern angeboten werden.
                Nähere Informationen hierzu erhalten Sie bei Angabe Ihrer personenbezogenen Daten oder untenstehend in der
                Beschreibung des Angebotes.
                <br><br>
                (4) Soweit unsere Dienstleister oder Partner ihren Sitz in einem Staat außerhalb des Europäischen
                Wirtschaftsraumen (EWR) haben, informieren wir Sie über die Folgen dieses Umstands in der Beschreibung des
                Angebotes.
                <br><br>
                Einbindung von Diensten und Inhalten Dritter
                <br><br>
                Unser Angebot umfasst mitunter Inhalte, Dienste und Leistungen anderer Anbieter. Das sind zum Beispiel
                Karten, die von Google-Maps zur Verfügung gestellt werden, Videos von YouTube sowie Grafiken und Bilder
                anderer Webseiten. Damit diese Daten im Browser des Nutzers aufgerufen und dargestellt werden können, ist
                die Übermittlung der IP-Adresse zwingend notwendig. Die Anbieter (im Folgenden als ,Dritt-Anbieter“
                bezeichnet) nehmen also die IP-Adresse des jeweiligen Nutzers wahr.
                <br><br>
                Auch wenn wir bemüht sind, ausschließlich Dritt-Anbieter zu nutzen, welche die IP-Adresse nur benötigen, um
                Inhalte ausliefern zu können, haben wir keinen Einfluss darauf, ob die IP-Adresse möglicherweise gespeichert
                wird. Dieser Vorgang dient in dem Fall unter anderem statistischen Zwecken. Sofern wir Kenntnis davon haben,
                dass die IP-Adresse gespeichert wird, weisen wir unsere Nutzer darauf hin.
                <br><br>
                Verwendung von Google Analytics
                <br><br>
                Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. (“Google”). Google Analytics
                verwendet sog. “Cookies”, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der
                Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
                Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort
                gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von
                Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
                Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
                IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                <br><br>
                Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre Nutzung der
                Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der
                Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu
                erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende
                Einstellung Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.
                <br><br>
                Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite
                bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
                verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
                Browser Add On zur Deaktivierung von Google Analytics.
                <br><br>
                Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
                http://www.google.com/analytics/terms/de.html bzw. unter https://www.google.de/intl/de/policies/.
                <br><br>
                Google AdWords
                <br><br>
                Diese Webseite nutzt das Online-Werbeprogramm „Google AdWords“ und im Rahmen dessen das Conversion-Tracking.
                Dabei wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt, sofern Sie über eine Google-Anzeige auf
                unsere Webseite gelangt sind. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der
                persönlichen Identifizierung. Besucht der Nutzer bestimmte Seiten unserer Webseite und das Cookie ist noch
                nicht abgelaufen, können wir und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser
                Seite weitergeleitet wurde. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht
                über die Webseiten von AdWords-Kunden nachverfolgt werden.
                <br><br>
                Die mit Hilfe des Conversion-Cookies eingeholten Informationen dienen dazu Conversion-Statistiken für
                AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die
                Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag
                versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
                persönlich identifizieren lassen.
                <br><br>
                Wenn Sie nicht am Tracking-Verfahren teilnehmen möchten, können Sie das Cookie des Google
                Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie werden
                dann nicht in die Conversion-Tracking Statistiken aufgenommen. Mehr hierzu erfahren Sie über die
                Datenschutzbestimmungen von Google unter http://www.google.de/policies/privacy/
                <br><br>
                Google reCAPTCHA
                <br><br>
                Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist die Google Inc.,
                1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
                <br><br>
                Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in einem
                Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert
                reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt
                automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene
                Informationen aus (z.B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer
                getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.
                <br><br>
                Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen,
                dass eine Analyse stattfindet.
                <br><br>
                Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor SPAM
                zu schützen.
                <br><br>
                Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie folgenden
                Links: https://www.google.com/intl/de/policies/privacy/ und
                https://www.google.com/recaptcha/intro/android.html.
                <br><br>
                Google Web Fonts
                <br><br>
                Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
                bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
                Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
                <br><br>
                Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen.
                Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die
                Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer
                Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
                <br><br>
                Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
                <br><br>
                Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in
                der Datenschutzerklärung von Google: https://www.google.com/policies/privacy/.
                <br><br>
                Google Maps
                <br><br>
                Wir setzen auf unserer Webseite ein Plugin des Internetdienstes Google Maps ein. Betreiber von Google Maps
                ist Google Inc., ansässig in den USA, CA 94043, 1600 Amphitheatre Parkway, Mountain View. Indem Sie Google
                Maps auf unserer Webseite nutzen, werden Informationen über die Nutzung dieser Webseite und Ihre IP-Adresse
                an einen Google-Server in den USA übermittelt und auch auf diesem Server gespeichert. Wir haben keine
                Kenntnis über den genauen Inhalt der übermittelten Daten, noch über ihre Nutzung durch Google. Das
                Unternehmen verneint in diesem Kontext die Verbindung der Daten mit Informationen aus anderen
                Google-Diensten und die Erfassung personenbezogener Daten. Allerdings kann Google die Informationen an
                Dritte übermitteln. Wenn Sie Javascript in Ihrem Browser deaktivieren, verhindern Sie die Ausführung von
                Google Maps. Sie können dann aber auch keine Kartenanzeige auf unserer Webseite nutzen. Mit der Nutzung
                unserer Webseite erklären Sie Ihr Einverständnis mit der beschriebenen Erfassung und Verarbeitung der
                Informationen durch Google Inc.. Näheres zu den Datenschutzbestimmungen und Nutzungsbedingungen für Google
                Maps erfahren Sie hier: https://www.google.com/intl/de_de/help/terms_maps.html.
                <br><br>
                <strong>§ 5 Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</strong>
                <br><br>
                (1) Falls Sie uns eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit
                widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen
                Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.
                <br><br>
                (2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie
                Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur
                Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung
                der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe,
                weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres
                begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw.
                anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
                fortführen.
                <br><br>
                (3) Auch können Sie der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und Datenanalyse
                jederzeit widersprechen. Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten übermitteln:
                info@flying-eagle24.de bzw.: Flying Eagle UG (haftungsbeschränkt), Höhenweg 7, 35452 Heuchelheim.
                <br><br>
                <strong>§ 6 Datenschutzbeauftragter</strong>
                <br><br>
                Wenn Sie Fragen zum Schutz ihrer personenbezogenen Daten haben oder sich über unseren Umgang mit
                personenbezogenen Daten beschweren möchten, können Sie dies jederzeit kostenlos tun, indem sie sich an
                unseren Datenschutzbeauftragten (Namen nennen) wenden. Sie erreichen ihn per E-Mail unter
                info@flying-eagle24.de oder per Post an unsere im Impressum genannte Adresse mit dem Vermerk “An den
                Datenschutzbeauftragten”.
                </p>
            </v-card>
        </div>
    </v-container>
    <FooterComponent />
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
export default {
    computed: {
        title() {
            return process.env.VUE_APP_TITLE
        },
        tel() {
            return process.env.VUE_APP_TEL
        },
        mail() {
            return process.env.VUE_APP_MAIL
        }
    },
    components: {
        FooterComponent
    }
}
</script>

<style>
.privacy-container {
    padding: 0;
}

.privacy {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    padding: 3%;
    padding-left: 10%;
    padding-right: 10%;
}

.privacy-card {
    padding: 5%;
    padding-top: 2%;
    width: 100%;
}

.privacy h3 {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    font-size: 20px;
}

.privacy a,
.privacy p {
    color: black;
    font-size: 16px;
}

.privacy ul {
    padding-left: 20px;
}

.sub-nav {
    width: 100%;
    background: #2496C9;
    color: white;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
}

.sub-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
}

.sub-col:nth-of-type(1) {
    justify-content: flex-start;
}

.sub-col:nth-of-type(3) {
    justify-content: flex-end;
}

.home-btn {
    font-size: 16px;
}

.sub-phone {
    font-size: 16px;
}

@media only screen and (max-width: 1000px) {

    .sub-col:nth-of-type(2) {
        justify-content: flex-end;
    }

    .sub-col:nth-of-type(3) {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .sub-nav {
        height: fit-content;
        justify-content: center;
        flex-direction: column;
    }

    .sub-col:nth-of-type(1) {
        padding-top: 20px;
        justify-content: center;
    }

    .sub-col:nth-of-type(2) {
        justify-content: center;
    }

    .sub-nav #logo {
        min-width: 200px;
    }

    .home-btn {
        margin-bottom: 10px;
    }
}
</style>