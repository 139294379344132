<template>
    <v-footer class="footer-styles text-center d-flex flex-column">
        <div class="social">
            <v-btn v-for="icon in icons" :key="icon" class="mx-4" :icon="icon" variant="text"
                style="font-size: 20px;"></v-btn>
        </div>

        <div class="footer-links">
            <router-link class="footer-link" :to="'/standorte'"><v-btn variant="text">
                    <p>Standorte</p>
                </v-btn></router-link>
            <router-link class="footer-link" :to="'/impressum'"><v-btn variant="text">
                    <p>Impressum</p>
                </v-btn></router-link>
            <router-link class="footer-link" :to="'/datenschutz'"><v-btn variant="text">
                    <p>Datenschutz</p>
                </v-btn></router-link>
        </div>

        <div class="seo-txt">
            <h3>{{ title }}: Ihr Profi für Rohr- und Kanalreinigung</h3>
            <br>
            <h3>Warum ist Rohrreinigung so wichtig?</h3>
            <br>
            <p>Es gibt wenig Dinge, die einen Haushalt oder ein Geschäft mehr stören können als eine unerwartete
                Rohrverstopfung. Plötzlich werden alltägliche Tätigkeiten, wie das Spülen des Geschirrs, zur
                Herausforderung. Es ist in solchen Zeiten, dass Sie die wahre Bedeutung einer effizienten Rohrreinigung zu
                schätzen wissen. Bei {{ title }} sind wir Experten für Rohrreinigung und sorgen dafür, dass Ihr
                Abwassersystem reibungslos funktioniert.</p>
            <br>
            <h3>Die Herausforderungen einer Rohrverstopfung</h3>
            <br>
            <p>Eine Rohrverstopfung kann zu einer Reihe von Problemen führen, von Wasserschäden bis hin zu unangenehmen
                Gerüchen. Zudem kann es zu einem Anstieg der Wasserrechnung kommen, wenn das Problem nicht zeitnah gelöst
                wird. Glücklicherweise bietet unser Team bei {{ title }} schnelle und effektive Lösungen für Ihre
                Rohrverstopfung.
            </p>
            <br>
            <h3>Was wir tun</h3>
            <br>
            <p>Als professionelle Rohrreiniger verwenden wir spezielle Geräte, um Ihre Rohre gründlich zu reinigen und
                Verstopfungen zu beseitigen. Wir sorgen auch dafür, dass Ihre Rohre in einem guten Zustand sind, um
                zukünftige Probleme zu vermeiden.
            </p>
            <br>
            <h3>Unser Angebot: Von Rohrreinigung bis Kanalreinigung</h3>
            <br>
            <p>Unser Service bei {{ title }} geht über die reine Rohrreinigung hinaus. Wir bieten eine umfassende
                Palette von Dienstleistungen an, darunter auch Kanalreinigung. Bei der Kanalreinigung entfernen wir
                Ablagerungen und Fremdkörper aus den Kanälen, um einen freien Wasserfluss zu gewährleisten. Dies kann
                helfen, teure Reparaturen und Ausfälle in der Zukunft zu vermeiden.</p>
            <br>
            <h3>Der Nutzen der Abflussreinigung</h3>
            <br>
            <p>Die regelmäßige Abflussreinigung ist ein weiterer wesentlicher Service, den wir anbieten. Abflüsse sind
                besonders anfällig für Verstopfungen, da sie oft mit Essensresten, Fett und Haaren in Kontakt kommen. Durch
                unsere professionelle Abflussreinigung sorgen wir dafür, dass Ihr Abwasser reibungslos abfließt.</p>
            <br>
            <h3>Unser 24/7 Rohrreinigung-Notdienst</h3>
            <br>
            <p>Weil wir verstehen, dass Rohrverstopfungen und andere Rohrprobleme nicht warten, bieten wir bei {{ title }}
                einen 24/7 Rohrreinigung-Notdienst an. Egal wann und wo, unser Team ist bereit, Ihnen bei Ihren
                Rohrproblemen zu helfen. Wir sind nur einen Anruf entfernt und bereit, Ihnen unseren professionellen Service
                zu bieten.</p>
            <br>
            <h3>Kontaktieren Sie {{ title }} heute</h3>
            <br>
            <p>
                Vertrauen Sie uns bei {{ title }}, um Ihre Rohr- und Kanalreinigung zuverlässig und effizient
                durchzuführen. Mit unserer Erfahrung und unserem Engagement für Kundenzufriedenheit sind wir Ihr Partner für
                alle Belange rund um die Rohrreinigung. Kontaktieren Sie uns heute und erfahren Sie, wie wir Ihnen helfen
                können.
            </p>
        </div>

        <div class="rights">
            <strong>&copy; Copyright {{ new Date().getFullYear() }} - All rights reserved</strong>
        </div>
    </v-footer>
</template>

<script>
export default {
    data() {
        return {
            icons: [
                'mdi-facebook',
                'mdi-twitter',
                'mdi-linkedin',
                'mdi-instagram',
            ],
        }
    },
    computed: {
        title() {
            return process.env.VUE_APP_TITLE
        }
    }
};
</script>

<style>
.footer-styles {
    background: #2496C9;
    color: white;
    padding: 0;
}

.social,
.rights {
    width: 100%;
    padding: 25px;
}

.social {
    display: none;
}

.rights {
    background: #111111;
    font-size: 14px;
    text-align: center;
}

.footer-links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 25px;
    background: #222222;
    width: 100%;
}

.footer-link {
    margin-left: 50px;
    margin-right: 50px;
}

.footer-link p {
    font-size: 14px;
}

.seo-txt {
    background: #111111;
    font-size: 14px;
    height: 0px;
    overflow: hidden;
    text-align: center;
}

@media screen and (max-width: 800px) {
    .footer-link p {
        font-size: 12px;
    }

    .footer-link {
        margin-left: 0px;
        margin-right: 0px;
    }
}</style>