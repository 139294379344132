<template>
    <v-container class="about-container" fluid>
        <v-row class="about-story-container">
            <v-col class="about-title" cols="12" md="6" v-intersect="() => $emit('intersect', 'about')">
                <h2>Über uns</h2>
            </v-col>
            <v-col class="about-description" cols="12" md="6">
                <p>Willkommen bei {{ title }}, Ihrem Experten für professionelle Rohrreinigung, Kanalreinigung und
                    Abflussreinigung. Wir sind Ihr zuverlässiger Partner, wenn es um die Beseitigung von Rohrverstopfungen
                    geht. Unser engagiertes Team steht Ihnen rund um die Uhr mit unserem Rohrreinigung-Notdienst zur
                    Verfügung. Verlassen Sie sich auf unsere Fachkenntnisse und modernste Ausrüstung, um Ihre Rohrprobleme
                    effizient und schnell zu lösen. Kontaktieren Sie uns noch heute für eine gründliche und professionelle
                    Rohrreinigung!</p>
            </v-col>
        </v-row>

        <v-row class="about-customer-container" ref="comments">
            <v-col class="about-customer-comments" cols="12" md="6">
                <v-card v-for="comment in comments" :key="comment.id" class="comment-card" color="#2496C9" theme="dark"
                    max-width="600">
                    <v-card-actions>
                        <v-list-item>
                            <template v-slot:prepend>
                                <v-avatar color="transparent"
                                    :image="require('../assets/' + comment.customer_image)"></v-avatar>
                            </template>
                            <v-list-item-title style="font-size: 18px;">{{ comment.customer_name }}</v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px; margin-top: 5px;">{{ comment.customer_detail }} <v-icon
                                    icon="mdi-star"></v-icon></v-list-item-subtitle>
                        </v-list-item>
                    </v-card-actions>
                    <v-card-text class="text-h6 py-5">
                        <p>{{ comment.text }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="about-customer" cols="12" md="6">
                <h2>Kundenbewertungen</h2>
                <v-rating half-increments disabled v-model="rating" bg-color="orange-lighten-1" color="white"></v-rating>
                <p>Akutelle Kundenbewertungen von {{ title }} auf Google</p>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    data() {
        return {
            comments: [
                {
                    id: 1,
                    customer_image: "claus_himmel_avatar.png",
                    customer_name: "Claus Himmel",
                    customer_detail: "Google Rating 5.0",
                    text: '"Immer wieder gerne. Schnell, professionell und zum fairen Preis. Auf jedem Fall mit gutem Gewissen zum Weiterempfehlen, was ich auch tun werde. Daumen hoch."'
                },
                {
                    id: 2,
                    customer_image: "elsa_seiler_avatar.png",
                    customer_name: "Elsa Seiler",
                    customer_detail: "Google Rating 5.0",
                    text: '"Preiswert, kompetent und auffällig sympathisch !!! 100% Weiterempfehlung !!! Er hat deutlich mehr leisten müssen als ursprünglich am Telefon von mir suggeriert, dennoch mit viel Geduld jede weitere Maßnahme ergriffen bis das Problem gelöst war. Danke dafür!"'
                },
                {
                    id: 3,
                    customer_image: "barbara_graf_avatar.png",
                    customer_name: "Barbara Graf",
                    customer_detail: "Google Rating 5.0",
                    text: '"Ich war sehr positiv überrascht - professionelle und schnelle Abwicklung, mod. Technik und faire Preise. Und dazu noch ein netter Kontakt. So etwas findet man selten. Einfach Klasse. Super Service. Weiter so"'
                }
            ],
            rating: 5
        };
    },
    computed: {
        title() {
            return process.env.VUE_APP_TITLE
        }
    }
};
</script>

<style>
.about-container {
    background-color: white;
    box-shadow: 1px 1px 5px lightgray;
    padding: 0;
    margin: 0;
}

.about-title {
    background-color: #2496C9;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.about-description {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    font-size: 1.5rem;
}

.about-customer-container,
.about-story-container {
    display: flex;
    flex-direction: row;
    margin: 0 !important;
}

.about-customer {
    background-color: #2496C9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
}

.about-customer p {
    color: white;
    margin: 10px;
    font-size: 18px;
    text-align: center;
}

.about-customer h2 {
    text-align: center;
    font-size: 2.5rem;
    color: white;
    padding-top: 50px;
}

.about-customer-image {
    padding: 50px;
}

.about-customer-comments {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.comment-card {
    margin-bottom: 25px;
    min-width: 80%;
    padding: 10px;
}

.comment-card p {
    font-size: 18px;
    line-height: 120%;
}

@media only screen and (max-width: 960px) {
    .about-description {
        text-align: center;
        font-size: 20px;
        padding: 35px;
    }

    .about-customer-comments {
        padding: 25px;
    }

    .about-customer-container {
        flex-direction: column-reverse;
        width: 100%;
    }

    .about-story-container {
        flex-direction: column;
        width: 100%;
    }

    .comment-card p {
        font-size: 16px;
    }

    .about-customer p {
        margin: 5px;
    }
}
</style>