<template>
    <v-container class="leistung-container" fluid>
        <v-row class="leistung-card-container">
            <v-col cols="12" md="12">
                <h2 class="text-center">Unsere Leistungen</h2>
            </v-col>
            <v-col v-for="leistung in leistungen" :key="leistung.id" class="leistung-col">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="leistung-card" :elevation="isHovering ? 12 : 2" v-bind="props" link
                        @click="leistung.show = !leistung.show">
                        <v-img class="leistung-img" :src="require('../assets/' + leistung.bild)" cover
                            :alt="title() + ' ' + leistung.ueberschrift"></v-img>
                        <v-card-title class="leistung-title white--text">{{ leistung.ueberschrift
                        }}</v-card-title>
                        <v-card-actions class="leistung-card-actions">
                            <p>Mehr erfahren</p>
                            <v-spacer></v-spacer>
                            <v-btn icon>
                                <v-icon style="font-size: 24px;">{{ leistung.show ? 'mdi-chevron-up' : 'mdi-chevron-down'
                                }}</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-expand-transition>
                            <v-card v-if="leistung.show" class="v-card--reveal" style="height: 100%;">
                                <v-card-title class="leistung-title white--text">{{ leistung.ueberschrift
                                }}</v-card-title>
                                <v-card-text class="leistung-card-text">
                                    <v-divider></v-divider>
                                    <p>{{ leistung.beschreibung }}</p>
                                    <ul>
                                        <li v-for="(item, i) in leistung.list" :key="i" style="margin-top: 5px;">
                                            <p style="display: inline;">{{ item }}
                                                <v-icon icon="mdi-check" size="medium"
                                                    :alt="item + i + '-mdi-check'"></v-icon>
                                            </p>
                                        </li>
                                    </ul>
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <div v-intersect="() => $emit('intersect', 'leistungen')"></div>
        <v-row class="slider-container" style="display: none">
            <h3 class="text-center">Überzeugen Sie sich selbst!</h3>
            <v-carousel :continuous="false" :show-arrows="false" cycle hide-delimiter-background delimiter-icon="mdi-square"
                height="300" color="#fff">
                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                    <v-sheet :color="colors[i]" height="100%" tile>
                        <div class="d-flex fill-height justify-center align-center">
                            <div class="text-h2" style="color: #fff">
                                {{ slide }} Slide
                            </div>
                        </div>
                    </v-sheet>
                </v-carousel-item>
            </v-carousel>
        </v-row>
        <div v-intersect="() => $emit('intersect', 'leistungen')"></div>
        <v-row class="vorteile-row">
            <v-col cols="12" md="12">
                <h2 class="text-center">Ihre Vorteile</h2>
            </v-col>
            <v-col cols="12" md="4">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-alarm-light" size="75" :alt="title() + ' Sofort erreichbar'"></v-icon>
                            <p>Sofort erreichbar</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="4">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-truck-fast" size="75" :alt="title() + ' Schnelles Handeln'"></v-icon>
                            <p>Kostenlose Anfahrt</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="4">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-hammer-wrench" size="75" :alt="title() + ' Moderne Geräte'"></v-icon>
                            <p>Moderne Geräte</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-account-cowboy-hat" size="75"
                                :alt="title() + ' Erfahrener Fachmann'"></v-icon>
                            <p>Erfahrener Fachmann</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-clock" size="50" :alt="title() + ' 24/7 erreichbar'"></v-icon>
                            <p>24/7 erreichbar</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-thumb-up" size="50" :alt="title() + ' Faire Preise & Terminsicher'"></v-icon>
                            <p>Faire Preise & Terminsicher</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" md="3">
                <v-hover v-slot="{ isHovering, props }">
                    <v-card class="vorteile-card" :elevation="isHovering ? 12 : 2" v-bind="props">
                        <v-card-text class="vorteile-card-text">
                            <v-icon icon="mdi-account" size="50"></v-icon>
                            <p>Privat- & Geschäftskunden</p>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            leistungen: [
                {
                    id: 1,
                    ueberschrift: "Abflussreinigung",
                    bild: "abfluss.webp",
                    beschreibung: "Beseitigung von Verstopfungen jeglicher Art in Abfluss und Anschlussleitungen:",
                    list: [
                        "Toilette, Urinal",
                        "Badewanne, Dusche",
                        "Waschbecken",
                        "Küche"
                    ],
                    show: false
                },
                {
                    id: 2,
                    ueberschrift: "Rohr- und Kanalreinigung",
                    bild: "reinigung.webp",
                    beschreibung: "Beseitigung von Verstopfung jeglicher Art:",
                    list: [
                        "Kanal/Grundrohre",
                        "DN 100 bis 200",
                        "Bodeneinläufe",
                        "Kelller und Schächte",
                        "Innen und Außen",
                        "Fallleitungen (DN 50-100)",
                    ],
                    show: false
                },
                {
                    id: 3,
                    ueberschrift: "Rohr- und Kanalsanierung",
                    bild: "kanal-sanierung.webp",
                    beschreibung: "Sanierungen jeglicher Art:",
                    list: [
                        "alle Bauweisen",
                        "DN 50 bis 200",
                        "Inliner-Sanierung",
                        "Kurzliner-Sanierung",
                        "Longliner-Sanierung"
                    ],
                    show: false
                },
                {
                    id: 4,
                    ueberschrift: "TV-Kamera-Untersuchung",
                    bild: "inspection.webp",
                    beschreibung: "Rohr- und Kanaluntersuchung mittels Kamera:",
                    list: [
                        "DN 30 bis 200",
                        "Bilder und Videos",
                        "Hochauflösende Qualität"
                    ],
                    show: false
                }
            ],
            colors: [
                '#2496C9',
                '#2496C9',
                '#2496C9',
                '#2496C9',
                '#2496C9'
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
            ],
            title() {
                return process.env.VUE_APP_TITLE
            }
        };
    }
};
</script>

<style>
.leistung-container {
    width: 80%;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0;
}

.leistung-card-container {
    flex-wrap: wrap;
}

.leistung-container h2 {
    font-size: 2.5rem;
    text-align: center;
    background-color: #2496C9;
    color: #fff;
    padding: 10px;
}

.leistung-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.leistung-card {
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
    position: relative;
    height: 20vw;
    min-height: 350px;
    min-width: 300px;
    display: flex;
    flex-flow: column;
}

.leistung-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #2496C9;
    color: white;
    font-size: 18px;
    line-height: 140% !important;
    min-height: 50px;
    padding: 15px;
    text-align: left;
    white-space: normal;
    overflow: visible;
    width: 100%;
}

.leistung-card-text {
    padding: 15px;
    background-color: #2496C9;
    color: white;
    height: 100%;
}

.leistung-card ul {
    list-style-position: inside;
    padding-top: 10px;
}

.leistung-img {
    flex: 1 1 auto;
}

.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

.leistung-card-text p {
    font-size: 18px;
    line-height: 120%;
    margin-top: 10px;
    text-align: left;
}

.leistung-card-actions {
    display: flex;
    align-items: center;
    padding-left: 15px;
    background: #333;
    color: white;
    position: relative;
    z-index: 10;
    font-size: 16px;
}

.vorteile-row {
    margin-top: 20px;
}

.vorteile-card {
    height: 250px;
    width: 100%;
    cursor: pointer;
}

.vorteile-card-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 1.5rem;
}

.vorteile-card-text p {
    line-height: 120%;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

@media only screen and (max-width: 1200px) {
    .leistung-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 800px) {
    .leistung-container {
        width: 95%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .leistung-card {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 350px) {

    .leistung-card {
        min-width: 200px;
    }
}
</style>