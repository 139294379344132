<template>
    <!--Nav-->
    <v-toolbar style="position: fixed; z-index: 100;">
        <div class="toolbar-styles">
            <v-img src="../assets/logo.webp" id="logo" alt="Rohrreinigung Hessen Logo" contain></v-img>
            <v-toolbar-items class="toolbar-links">
                <v-btn :class="{ 'highlighted': activeButton === 'home' }" flat
                    @click="activateButton('home')">Start</v-btn>
                <v-btn :class="{ 'highlighted': activeButton === 'leistungen' }" flat
                    @click="activateButton('leistungen')">Leistungen</v-btn>
                <v-btn :class="{ 'highlighted': activeButton === 'about' || activeButton === 'comments' }" flat
                    @click="activateButton('about')">Über
                    uns</v-btn>
                <v-btn :class="{ 'highlighted': activeButton === 'contact' }" flat
                    @click="activateButton('contact')">Kontakt</v-btn>
                <v-btn :class="{ 'highlighted': activeButton === 'faq' }" flat @click="activateButton('faq')">FAQ</v-btn>
            </v-toolbar-items>
            <v-app-bar-nav-icon id="burger" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-btn prepend-icon="mdi-phone" variant="tonal" id="nav-phone">
                <a :href="'tel:' + tel">{{ tel }}</a>
            </v-btn>
        </div>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" location="bottom" temporary color="#333" id="drawer">
        <v-list class="drawer-list">
            <v-list-item @click="activateButton('home')" class="drawer-item">Start</v-list-item>
            <v-list-item @click="activateButton('leistungen')" class="drawer-item">Leistungen</v-list-item>
            <v-list-item @click="activateButton('about')" class="drawer-item">Über uns</v-list-item>
            <v-list-item @click="activateButton('contact')" class="drawer-item">Kontakt</v-list-item>
            <v-list-item @click="activateButton('faq')" class="drawer-item">FAQ</v-list-item>
        </v-list>
    </v-navigation-drawer>
    <!--Home-->
    <HomeComponent ref="home" @intersect="(ref) => !this.lock ? this.activeButton = ref : null"
        @scroll="activateButton('comments')" />
    <!--Leistungen-->
    <v-scroll-x-transition hide-on-leave>
        <LeistungComponent ref="leistungen" @intersect="(ref) => onIntersect(ref)" />
    </v-scroll-x-transition>
    <!--About-->
    <AboutComponent ref="about" @intersect="(ref) => onIntersect(ref)" />
    <!--Contact-->
    <ContactComponent ref="contact" @intersect="(ref) => onIntersect(ref)" />
    <!--FAQ-->
    <FaqComponent ref="faq" @intersect="(ref) => onIntersect(ref)" />
    <!--Footer-->
    <FooterComponent />
</template>

<script>
import HomeComponent from "@/components/HomeComponent.vue";
import LeistungComponent from "@/components/LeistungComponent.vue";
import AboutComponent from "@/components/AboutComponent.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import FaqComponent from "@/components/FaqComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
    data() {
        return {
            activeButton: null,
            scrollY: 0,
            lock: false,
            drawer: false,
            group: null,
        }
    },
    computed: {
        title() {
            return process.env.VUE_APP_TITLE
        },
        tel() {
            return process.env.VUE_APP_TEL
        }
    },
    components: {
        HomeComponent, // Home
        LeistungComponent, // Leistungen
        AboutComponent, // About
        ContactComponent, // Contact,
        FaqComponent, // FAQ,
        FooterComponent //Footer
    },
    methods: {
        activateButton(ref) {
            this.activeButton = ref;
            if (this.$refs[ref] === undefined) {
                this.$refs['about'].$refs[ref].$el.scrollIntoView({ behavior: "smooth" })
            } else {
                this.$refs[ref].$el.scrollIntoView({ behavior: "smooth" })
            }
            this.lock = true;
            setTimeout(() => this.lock = false, 500)
        },
        onIntersect(ref) {
            if (this.scrollY > 0 && !this.lock) {
                this.activeButton = ref
            }
        },
        watchDrawer() {
            this.drawer = false;
        }
    },
    watch: {
        group() {
            this.drawer = false
        }
    },
    created() {
        // Only execute this code on the client side, server sticks to [0, 0]
        if (!this.$isServer) {
            this._scrollListener = () => {
                // window.pageX/YOffset is equivalent to window.scrollX/Y, but works in IE
                // We round values because high-DPI devies can provide some really nasty subpixel values
                this.scrollY = Math.round(window.pageYOffset)
            }

            // Call listener once to detect initial position
            this._scrollListener()

            // When scrolling, update the position
            window.addEventListener('scroll', this._scrollListener)
            window.addEventListener('resize', this.watchDrawer)
        }
    },
    beforeUnmount() {
        // Detach the listener when the component is gone
        window.removeEventListener('scroll', this._scrollListener)
        window.removeEventListener('resize', this.watchDrawer)
    }
};
</script>
  
<style>
.toolbar-styles {
    background: #2496C9;
    color: white;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

#logo {
    max-width: 225px;
}

#burger {
    display: none;
    font-size: 20px;
}

.highlighted {
    background-color: black;
}

.drawer-item {
    color: white;
    text-align: center;
    font-size: 16px;
}

#drawer,
.drawer-list,
.v-navigation-drawer__content {
    overflow: hidden
}

#nav-phone {
    font-size: 16px;
}

@media only screen and (max-width: 1000px) {
    .toolbar-links {
        display: none;
    }

    #burger {
        display: block;
    }

    #nav-phone {
        display: none;
    }

    #logo {
        max-width: 200px;
    }
}
</style>

