<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script></script>

<style>

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: white;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #000000 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 0px;
  border: 0px solid #ffffff;
}

@media only screen and (max-width: 1200px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1000px) {
  :root {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    font-size: 10px;
  }
}
</style>
