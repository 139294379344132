<template>
    <v-container class="backlink-container" fluid>
        <v-row class="sub-nav">
            <v-col class="sub-col">
                <v-img src="../assets/logo.webp" id="logo" contain></v-img>
            </v-col>
            <v-col class="sub-col">
                <router-link :to="'/'" >
                    <v-btn color="#fff" variant="tonal" class="home-btn">
                        Startseite
                    </v-btn>
                </router-link>
            </v-col>
            <v-col class="sub-col">
                <v-btn class="sub-phone" prepend-icon="mdi-phone" variant="tonal">
                    <a :href="'tel:' + tel">{{ tel }}</a>
                </v-btn>
            </v-col>
        </v-row>
        <div class="backlinks">
            <v-card class="backlink-card">
                <v-list :items="items" class="backlink-list">
                    <div class="item" v-for="(item, i) in items" :key="i">
                        <a :href=item.link target="_blank"><v-list-item :value="item.title"
                            color="primary">
                            <p>{{ item.title }}</p>
                        </v-list-item></a>
                        <v-divider v-if="i != items.length -1 "></v-divider>
                    </div>
                </v-list>
            </v-card>
        </div>
    </v-container>
    <FooterComponent />
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
export default {
    data: () => ({
        items: [
            {
                title: 'Rohrreinigung Schumacher Abtsteinach',
                link: 'https://rohrreinigung-abtsteinach.de',
                value: 1,
            },
            {
                title: 'Rohrreinigung Schumacher Ahnatal',
                link: 'https://rohrreinigung-ahnatal.de',
                value: 2,
            },
            {
                title: 'Rohrreinigung Schumacher Alheim',
                link: 'https://rohrreinigung-alheim.de',
                value: 3,
            },
            {
                title: 'Rohrreinigung Schumacher Alsbach-Hähnlein',
                link: 'https://rohrreinigung-alsbach-haehnlein.de',
                value: 4,
            },
            {
                title: 'Rohrreinigung Schumacher Angelburg',
                link: 'https://rohrreinigung-angelburg.de',
                value: 5,
            },
            {
                title: 'Rohrreinigung Schumacher Bad-Emstal',
                link: 'https://rohrreinigung-bad-emstal.de',
                value: 6,
            },
            {
                title: 'Rohrreinigung Schumacher Bad-Endbach',
                link: 'https://rohrreinigung-bad-endbach.de',
                value: 7,
            },
            {
                title: 'Rohrreinigung Schumacher Bad-Salzschlirf',
                link: 'https://rohrreinigung-bad-salzschlirf.de',
                value: 8,
            },
            {
                title: 'Rohrreinigung Schumacher Bad-Zwesten',
                link: 'https://rohrreinigung-bad-zwesten.de',
                value: 9,
            },
            {
                title: 'Rohrreinigung Schumacher Beselich',
                link: 'https://rohrreinigung-beselich.de',
                value: 10,
            },
            {
                title: 'Rohrreinigung Schumacher Bickenbach',
                link: 'https://rohrreinigung-bickenbach.de',
                value: 11,
            },
            {
                title: 'Rohrreinigung Schumacher Biebergemünd',
                link: 'https://rohrreinigung-biebergemuend.de',
                value: 12,
            },
            {
                title: 'Rohrreinigung Schumacher Biebesheim',
                link: 'https://rohrreinigung-biebesheim.de',
                value: 13,
            },
            {
                title: 'Rohrreinigung Schumacher Birkenau',
                link: 'https://rohrreinigung-birkenau.de',
                value: 14,
            },
            {
                title: 'Rohrreinigung Schumacher Birstein',
                link: 'https://rohrreinigung-birstein.de',
                value: 15,
            },
            {
                title: 'Rohrreinigung Schumacher Bischoffen',
                link: 'https://rohrreinigung-bischoffen.de',
                value: 16,
            },
            {
                title: 'Rohrreinigung Schumacher Brachttal',
                link: 'https://rohrreinigung-brachttal.de',
                value: 17,
            },
            {
                title: 'Rohrreinigung Schumacher Brechen',
                link: 'https://rohrreinigung-brechen.de',
                value: 18,
            },
            {
                title: 'Rohrreinigung Schumacher Breidenbach',
                link: 'https://rohrreinigung-breidenbach.de',
                value: 19,
            },
        ],
    }),
    components: {
        FooterComponent
    },
    computed: {
        title() {
            return process.env.VUE_APP_TITLE
        },
        tel() {
            return process.env.VUE_APP_TEL
        }
    },
}
</script>

<style>
.backlink-container {
    padding: 0;
}

.backlink-card {
    width: 75%;
    margin: 5%;
}

.backlinks {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.backlink-list {
    text-align: center;
}

.backlink-list p {
    font-size: 18px;
}

.backlink-list a {
    color: black;
}

.sub-nav {
    width: 100%;
    background: #2496C9;
    color: white;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
}

.sub-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
}

.sub-col:nth-of-type(1) {
    justify-content: flex-start;
}

.sub-col:nth-of-type(3) {
    justify-content: flex-end;
}

.home-btn {
    font-size: 16px;
}

.sub-phone {
    font-size: 16px;
}

.item a {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1000px) {

    .sub-col:nth-of-type(2) {
        justify-content: flex-end;
    }

    .sub-col:nth-of-type(3) {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .sub-nav {
        height: fit-content;
        justify-content: center;
        flex-direction: column;
    }

    .sub-col:nth-of-type(1) {
        padding-top: 20px;
        justify-content: center;
    }

    .sub-col:nth-of-type(2) {
        justify-content: center;
    }

    .sub-nav #logo {
        min-width: 200px;
    }

    .home-btn {
        margin-bottom: 10px;
    }
}
</style>