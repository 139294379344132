<template>
    <v-container class="impressum-container" fluid>
        <v-row class="sub-nav">
            <v-col class="sub-col">
                <v-img src="../assets/logo.webp" id="logo" contain></v-img>
            </v-col>
            <v-col class="sub-col">
                <router-link :to="'/'">
                    <v-btn color="#fff" variant="tonal" class="home-btn">
                        Startseite
                    </v-btn>
                </router-link>
            </v-col>
            <v-col class="sub-col">
                <v-btn class="sub-phone" prepend-icon="mdi-phone" variant="tonal">
                    <a :href="'tel:' + tel">{{ tel }}</a>
                </v-btn>
            </v-col>
        </v-row>

        <div class="impressum">
            <v-card class="impressum-card" :elevation="3">
                <h3>Impressum</h3>
                <p>
                    Diese Webseite ist ein Angebot der Firma:
                    <br><br>
                    Flying Eagle GmbH (Gesellschaft mit beschränkter Haftung, Stammkapital: 25.000€)<br>
                    Geschäftssitz: Höhenweg 7, 35452 Heuchelheim
                    <br><br>
                    Telefon: <a :href="'tel:' + tel"></a>{{ tel }}<br>
                    E-Mail: <a :href="'mailto:' + mail">{{ mail }}</a>
                    <br><br>
                    Vertretungsberechtigte Geschäftsführer: Herr Patrick Michael Seck
                    <br><br>
                    Registergericht: Amtsgericht Gießen<br>
                    Registernummer: HRB 10567<br>
                    Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE344929540
                </p>
                <h3>Kundeninformationen</h3>
                <p>
                    Die Webseite "rohrreinigung-lollar" ist eine Vermittlungszentrale. Wir sind eine reine Vermittlerfirma.
                    Aufträge werden an kompetente und sorgfältig ausgewählte Sanitärfirmen in Ihrer REGION vergeben.
                    Mit Ihrem Anruf in der Vermittlungszentrale geben Sie sich damit einverstanden Ihren Auftrag
                    weiterzuleiten und an ausgewählte Sanitärfirmen in Ihrer REGION zu vergeben.
                    Wir können nicht für die Preise, Ausführung, Schnelligkeit, und Qualität unserer Partnerfirmen haften.
                    Bei Auftragserteilung handelt es sich um einen Vertrag zwischen Kunde (Auftraggeber) und dem lokalen
                    Partner
                    vor Ort. Haftungsansprüche sind direkt gegenüber dem Partner vor Ort zu stellen, die Daten entnehmen Sie
                    bitte dem
                    Auftragsformular, welches Sie vor Ort ausgehändigt bekommen haben.
                    <br><br>
                    Verbraucherinformation gemäß Verordnung (EU) Nr. 524/2013: Zur außergerichtlichen Streitbeilegung von
                    verbraucherrechtlichen Streitigkeiten hat die Europäische Kommission eine Plattform zur
                    Online-Streitbeilegung (OS) eingerichtet. Die Plattform finden Sie unter
                    https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE.
                    <br><br>
                    Hinweis gemäß §36 Verbraucherstreitbeilegungsgesetz (VSBG): Wir werden nicht an einem
                    Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle im Sinne des VSBG teilnehmen und sind
                    hierzu auch nicht verpflichtet.
                </p>
                <h3>Haftung für Inhalte</h3>
                <p>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit
                    und
                    Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7
                    Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                    bis
                    10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                    Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                    einer
                    konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                    diese
                    Inhalte umgehend entfernen.
                </p>
                <h3>Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten
                    Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                    wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren
                    zum
                    Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
                    ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>
                <h3>Urheberecht</h3>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit
                    die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                    beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                    von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
                <h3>Credits</h3>
                <p>Webdesign von Tobias Walter.<br><br>
                    <ul style="list-style-position: inside; margin-bottom: 15px;">
                        <li><strong><a href="https://developerpagetw.netlify.app/" target="_blank">Website</a></strong></li>
                        <li><strong><a href="https://de.fiverr.com/tobiaswalter802" target="_blank">Fiverr</a></strong></li>
                    </ul>
                </p>


            </v-card>
        </div>
    </v-container>
    <FooterComponent />
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
export default {
    computed: {
        title() {
            return process.env.VUE_APP_TITLE
        },
        tel() {
            return process.env.VUE_APP_TEL
        },
        mail() {
            return process.env.VUE_APP_MAIL
        }
    },
    components: {
        FooterComponent
    }
}
</script>

<style>
.impressum-container {
    padding: 0;
}

.impressum {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    padding: 3%;
    padding-left: 10%;
    padding-right: 10%;
}

.impressum-card {
    padding: 5%;
    padding-top: 2%;
    width: 100%;
}

.impressum h3 {
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    font-size: 20px;
}

.impressum p,
.impressum a {
    font-size: 16px;
}

.impressum a {
    color: black;
}

.sub-nav {
    width: 100%;
    background: #2496C9;
    color: white;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
}

.sub-col {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
}

.sub-col:nth-of-type(1) {
    justify-content: flex-start;
}

.sub-col:nth-of-type(3) {
    justify-content: flex-end;
}

.home-btn {
    font-size: 16px;
}

.sub-phone {
    font-size: 16px;
}

@media only screen and (max-width: 1000px) {

    .sub-col:nth-of-type(2) {
        justify-content: flex-end;
    }

    .sub-col:nth-of-type(3) {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .sub-nav {
        height: fit-content;
        justify-content: center;
        flex-direction: column;
    }

    .sub-col:nth-of-type(1) {
        justify-content: center;
        padding-top: 20px;
    }

    .sub-col:nth-of-type(2) {
        justify-content: center;
    }

    .sub-nav #logo {
        min-width: 200px;
    }

    .home-btn {
        margin-bottom: 10px;
    }
}
</style>