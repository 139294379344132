<template>
  <v-container class="home-container" fluid>
    <v-img class="home-container-image" src="../assets/front-plumber-comp.webp" cover alt="Rohrreinigung Hessen Fachmann"></v-img>
    <div v-intersect="() => $emit('intersect', 'home')"></div>
    <v-card class="welcome-card" flat>
      <v-card-text class="text-left welcome-card-text">
        <h1>{{ title }}</h1>
        <p>Ihr kompetenter Partner für die professionelle Reinigung und Sanierung von Rohren, Abflüssen und Kanälen.</p>
        <a :href="'tel:' + tel">
        <v-btn id="home-phone" flat color="#2496C9">
          <v-icon icon="mdi-phone" style="margin-right: 5px;"></v-icon> {{ tel }}
        </v-btn>
        </a>
        <div class="google" @click="() => $emit('scroll', 'rating')">
          <img :src="require('../assets/google_g_icon.webp')" class="g-icon" alt="google-icon">
          <div class="rating-container">
            <p style="font-size: 1.4rem; margin-top: 25px;">Bewertungen unserer Kunden</p>
            <v-rating style="margin-right: 2px;" class="rating-home" disabled v-model="rating" half-increments bg-color="orange-lighten-1"
              color="blue"></v-rating>
          </div>
        </div>

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({ rating: 5 }),
  computed: {
    title() {
      return process.env.VUE_APP_TITLE
    },
    tel() {
      return process.env.VUE_APP_TEL
    }
  }
}
</script>

<style>
.home-container {
  height: 100vh;
  min-height: 700px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.home-container-image {
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 100%;
}

#home-phone {
  display: none;
  width: 200px;
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 12px;
  min-width: 200px;
}

.welcome-card {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  background: rgba(255, 255, 255, .75);
}

.welcome-card-text {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.welcome-card h1 {
  color: black;
  margin-bottom: 25px;
  font-size: 3.5rem;
  line-height: 120%;
  word-break: keep-all;
}

.welcome-card p {
  font-size: 2rem;
  line-height: 120%;
}

.rating-home {
  margin-bottom: 15px;
  margin-left: -15px;
  font-size: 50px;
}

.google {
  display: flex;
  align-items: center;
  margin-left: -15px;
  cursor: pointer;
  margin-top: 10px;
}

.g-icon {
  width: 80px;
  margin-right: 15px;
}

#home-phone {
  color: white;
  font-size: 16px;
  width: fit-content;
}

@media only screen and (max-width: 1200px) {
  .welcome-card {
    width: 75%;
  }
}

@media only screen and (max-width: 1000px) {
  .welcome-card {
    padding: 25px;
  }

  #home-phone {
    display: block;
  }

  .home-container {
    align-items: flex-end;
  }
}

@media only screen and (max-width: 800px) {
  .welcome-card {
    width: 100%;
  }

  .google {
    margin-top: -10px;
    margin-left: -10px;
  }

  .google p {
    transform: translateY(10px);
  }

  .g-icon {
    width: 65px;
  }

  .v-rating__item {
    width: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .home-container-image .v-img__img--cover {
    position: absolute;
    width: auto;
    left: -40%;
  }

  .g-icon {
    width: 50px;
    margin-right: 5px;
  }

  .google p {
    margin-left: 2px;
  }
}

@media only screen and (max-width: 300px) {
  .google {
    display: none;
  }
}
</style>